import React, {useEffect} from 'react'
import VisionCarousel from '../components/Vision Carousel';
import {FaCheckCircle} from 'react-icons/fa';

const WhoWeAre = () => {

useEffect(()=>{
document.title = 'About Us | Beda Civil Works & Contractors Limited';

},[]);


  return (
    <>

     
    <section className='section-cover who-we-are-cover' >
      <h2 className="home-h2 wwa-h2">About Us</h2>
          <div className='content-container vm-container'>
                 <div className='vision-mission-content'>
                    <article className='vision-mission-slides'>
                          <VisionCarousel></VisionCarousel>

                    </article>

                    <article className='vision-mission-info'>
                          <h3 className='title-h3'>Our Vision</h3>
                          
                           <div className='vm-line'></div>
                          <p>To provide quality services both personally and professionally in practice with non-advisory approach. This involves working to industry standards on measures and self-analysis. Maintain existing clients and using reputation to develop new clients and opportunities in all.
</p>
                          
                          <h3 className='title-h3'>Our Mission</h3>
                          <div className='vm-line'></div>
                          <p>To be recognized as a premier construction company in Uganda by 2030, for providing superior value to our customers and employee satisfaction.</p>

                    </article>

                    


                 </div>

          </div>
    </section>

     <section className='section-cover section-cover2'>
       <div className='content-container goals-container'>
           <article className='goals-text goals-text1 goals-text2'>
              {/* <h2 className='home-h2 home-service-h2 goals-h2'>Our Core Values</h2> */}
              <h3 className='title-h3'>Our Core Values</h3>
              <div className='vm-line'></div>
              <div className='goals-list'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description'><strong>Innovativeness and creativity: </strong>
 That the hall mark of our activities as we initiate and adapt to change.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description'><strong>Team work: </strong> 
 Ability to work with other companies in the industry.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description'><strong>Integrity:</strong> We do what we say we will do.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description'><strong>Professionalism: </strong>
 Beda Civil Works and Contractors Limited is a registered company dealing in construction, supply and
general enterprises.
We work both locally and on national levels.
We build on contracts with our clients at personal level and organizational/company level.
</p>
                     </div>

                     
                    

                </div>
                   

           </article>

           




       </div>

  </section>
    

    </>
  )
}

export default WhoWeAre