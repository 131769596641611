import Carousel from 'react-bootstrap/Carousel';

function ContactCarousel() {
  return (
    <Carousel indicators={false} className="contact-slides-cover">
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/43.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/44.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/45.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/46.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>

      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/48.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/29.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/30.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/31.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/32.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/33.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/34.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/35.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/36.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/38.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/39.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/40.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/41.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/42.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>
     {/* <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/1.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/2.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>

      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/3.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>

      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/4.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>

      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/5.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/6.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>

      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/7.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>

      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/8.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>

      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/9.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>

       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/10.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>


      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/11.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>

       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/12.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>

       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/13.jpg"
          alt="First slide"
        />
      </Carousel.Item>

      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/14.jpg"
          alt="First slide"
        />
      </Carousel.Item>

      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/15.jpg"
          alt="First slide"
        />
      </Carousel.Item>

      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/16.jpg"
          alt="First slide"
        />
      </Carousel.Item>

       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/17.jpeg"
          alt="First slide"
        />
      </Carousel.Item>

      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/18.jpg"
          alt="First slide"
        />
      </Carousel.Item>

      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/19.jpg"
          alt="First slide"
        />
      </Carousel.Item>

      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/20.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/21.jpeg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/22.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/23.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/24.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/25.jpg"
          alt="First slide"
        />
      </Carousel.Item> */}

    </Carousel>
  );
}

export default ContactCarousel;