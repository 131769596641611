import Carousel from 'react-bootstrap/Carousel';

function RoadCarousel() {
  return (
    <Carousel indicators={false} className='services-slides'>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/42.jpg"
          alt="First slide"
        />

      </Carousel.Item>

      {/* <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/7.jpg"
          alt="First slide"
        />
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/8.jpg"
          alt="First slide"
        />
      </Carousel.Item>


      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/9.jpg"
          alt="First slide"
        />
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/10.jpg"
          alt="First slide"
        />
      </Carousel.Item> */}
  
    </Carousel>
  );
}

export default RoadCarousel;