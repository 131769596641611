import Carousel from 'react-bootstrap/Carousel';

function MaintCarousel() {
  return (
    <Carousel indicators={false} className='services-slides'>
      
     
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/41.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
      {/* <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/15.jpg"
          alt="Third slide"
        />
      </Carousel.Item>

      */}

      
    </Carousel>
  );
}

export default MaintCarousel;